p > div {
  display: none;
}

.markdown pre {
  overflow: auto;
}

.markdown * {
  word-wrap: break-word;
}

.markdown ol,
.markdown ul {
  padding-left: 1.5rem;
}

span:has(> .select-trigger-is) {
  display: flex;
  width: 100%;
}
